$ThemeColor: rgb(220, 41, 26);
$ColorWhite: rgb(255, 2550, 255);
$ColorBlack: rgb(0,0,0);
$ColorPurple1: rgb(120, 35, 220);
$ColorPurple2: rgb(135, 55, 225);
$ColorPurple3: rgb(145, 80, 225);
$ColorPurple4: rgb(160, 100, 230);
$ColorPurple5: rgb(175, 125, 235);
$ColorPurple6: rgb(185, 145, 235);
$ColorPurple7: rgb(200, 165, 240);
$ColorPurple8: rgb(215, 190, 245);
$ColorPurple9: rgb(230, 210, 250);
$ColorSlate1: rgb(15, 15, 15);
$ColorSlate2: rgb(50, 50, 50);
$ColorSlate3: rgb(147, 10, 10);
$ColorSlate4: rgb(95, 95, 95);
$ColorSlate5: rgb(120, 120, 120);
$ColorSlate6: rgb(140, 140, 140);
$ColorSlate7: rgb(165, 165, 165);
$ColorSlate8: rgb(185, 185, 185);
$ColorSlate9: rgb(210, 210, 210);

$ColorV1: rgb(38, 55, 142);
$ColorV2: rgb(34, 98, 173);
$ColorV3: rgb(43, 133, 199);
$ColorV4: rgb(48, 161, 219);

$ColorSurveyBorder1: $ColorSlate2;
$ColorSurveyBorder2: $ColorBlack;
$ColorSurveyText: $ColorWhite;
$ColorSurveyProgressStart:rgb(215, 190, 245);
$ColorSurveyProgressEnd: rgb(120, 35, 220);
$ColorSurveyProgressBorder:$ThemeColor;
$ColorSurveyRadio:$ThemeColor;
$ColorSurveyControlFill:rgb(104, 77, 148);
$ColorSurveyControlAccent:rgb(135, 55, 225);
$ColorSurveyDropdownGray:rgb(210, 210, 210);
$ColorSurveyTableHeader:rgb(210, 210, 210);
$ColorSurveyTableRow:rgb(238, 238, 238);
$ColorSurveyTableAlternateRow:rgb(247, 247, 247);
$ColorSurveyQuestionsBackground: $ColorWhite;

$BackgroundColor: $ColorBlack;

$ToastrBlue:#7fbcec;
$ToastrGreen:#b7d8b7;
$ToastrRed:#f36b79;

@keyframes ui-progress-spinner-color {

    100%,
    0% {
        stroke: $ColorPurple1;
    }

    40% {
        stroke: $ColorPurple3;
    }

    66% {
        stroke: $ColorPurple5;
    }

    80% {
        stroke: $ColorPurple7;
    }

    90% {
        stroke: $ColorPurple9;
    }
}

.questionHeader {
    font-weight: bold;
    display: flex;
}

.questionNumberText {
    margin-right: 5px;
}

html,
body {
    letter-spacing: 0.02rem;
    height: auto;
    color: $ColorBlack;
    margin: 0;
}

.surveyQuestions {
    margin-left: 200px;
    margin-right: 200px;
}

.surveyLeftBorder3 {
    padding-left: 20px;
}

.tooltip,
.tooltipBelow {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 320px;
    background-color: $ColorSurveyRadio;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $ColorSurveyRadio transparent transparent transparent;
}

.tooltipBelow .tooltiptext {
    visibility: hidden;
    width: 320px;
    background-color: $ColorSurveyRadio;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    /* Use half of the width (120/2 = 60), to center the tooltip */

    opacity: 0;
    transition: opacity 0.3s;
}

.tooltipBelow .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 10%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $ColorSurveyRadio transparent;
}

.tooltip:hover .tooltiptext,
.tooltipBelow:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.button {
    background-color: $ThemeColor !important;
    border: 1px solid black;
}

.hint-style {
    position: absolute;
    background: white;
    line-height: 25px;
    color: lightgray;
    bottom: 11px;
    // z-index: 10;
    right: 10px;
}

.button-gray {
    background-color: gray !important;
    border: 1px solid black !important;
}